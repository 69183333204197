<template>
  <section class="sec">
    <header class="sec_hd">
      <h2 class="sec_hd_ttl">
        <em>{{ category }}</em>
        <small>{{ en }}</small>
        <strong v-if="paid" class="sec_hd_paid">有償コンテンツ</strong>
      </h2>
      <a class="sec_hd_link" @click="$router.push({ name: routerName })"
        >すべて見る</a
      >
    </header>
    <div
      v-if="loading || contents.length"
      class="sec_list"
      :class="{ c_load: loading }"
    >
      <a
        v-for="c in contents"
        :key="c.id"
        class="sec_list_item"
        @click="clickContents(c)"
        :content="c.description"
        v-tippy
        :style="accessableStyle(c)"
      >
        <img
          v-if="c.image_path"
          :src="`https://${jc3LearningDomain}${c.image_path}`"
          alt
          width="65"
          height="65"
        />
        <img
          v-else
          src="http://placehold.jp/cfcfcf/4a4a4a/65x65.png?text=NO%0AIMAGE"
          alt
          width="65"
          height="65"
        />
        <div
          class="sec_list_item_name"
          :class="{ '-new': isNew(c.from_field) }"
        >
          <p>new</p>
          <em>{{ c.name }}</em>
        </div>
      </a>
    </div>
    <div v-else style="text-align: center; padding:20px;">
      学習可能なコンテンツはありません。
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { jc3LearningDomain } from "@/../config.json";
export default {
  props: {
    category: String,
    en: String,
    routerName: String,
    paid: Boolean,
    loading: Boolean,
    contents: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
    };
  },
  methods: {
    clickContents(contents) {
      if (this.$auth.scopes.includes("readonly:npa")) {
        if (contents.category_name === "サイバー関連サービス等解説コンテンツ") {
          this.goContents(contents);
        } else {
          this.$emit("unauthorized");
        }
      } else {
        this.goContents(contents);
      }
    },
    goContents(contents) {
      if (contents.type === 1 || contents.type === 2) {
        // CTF or 教育
        let url = `https://${this.jc3LearningDomain}/events/${contents.id}`;
        window.open(url, "_blank");
      } else if (contents.type === 4) {
        // 外部サイト
        let url = contents.external_url;
        window.open(url, "_blank");
      } else if (contents.type === 8) {
        // 動画
        if (this.category === "サイバー最新トレンド") {
          this.$router.push({
            name: "TrendSingle",
            params: { id: contents.id },
          });
        } else if (this.category === "サイバー空間の脅威に立ち向かえ！") {
          this.$router.push({
            name: "FaceUpSingle",
            params: { id: contents.id },
          });
        } else if (this.category === "サイバー関連サービス等解説コンテンツ") {
          this.$router.push({
            name: "BasicsSingle",
            params: { id: contents.id },
          });
        }
      } else if (contents.type === 16) {
        // ツール
        this.$router.push({ name: "ToolSingle", params: { id: contents.id } });
      }
    },
    accessableStyle(contents) {
      if (!contents.accessable) {
        return {
          "pointer-events": "none",
          cursor: "not-allowed",
        };
      }
    },
    isNew(date) {
      // 3か月以内に作成されてればNEW
      return moment(date).isAfter(moment().subtract(3, "months"));
    },
  },
};
</script>
