<template>
  <div class="-home">
    <section-info></section-info>
    <div class="c_main -pad">
      <div class="inner">
        <div class="warp">
          <div class="main">
            <div class="menu">
              <a
                class="menu_item -item06"
                @click="$router.push({ name: 'Basics' })"
              >
                <em>
                  サイバー関連サービス等解説コンテンツ
                </em>
              </a>
              <a
                class="menu_item -item04"
                @click="$router.push({ name: 'Tool' })"
              >
                <em>みんなの支援ツール</em>
              </a>
              <a
                class="menu_item -item02"
                @click="$router.push({ name: 'Dojo' })"
              >
                <em>サイバー道場</em>
              </a>
              <a
                class="menu_item -item05"
                @click="$router.push({ name: 'FaceUp' })"
              >
                <em>
                  サイバー空間の
                  <br />脅威に立ち向かえ！
                </em>
              </a>
              <a
                class="menu_item -item01"
                @click="$router.push({ name: 'Trend' })"
              >
                <em>サイバー最新トレンド</em>
              </a>
              <a
                class="menu_item -item07"
                @click="$router.push({ name: 'Ctf' })"
              >
                <em>CTF</em>
              </a>
              <a
                class="menu_item -item08"
                @click="$router.push({ name: 'AdvancedDojo' })"
              >
                <em>上級道場</em>
              </a>
              <a
                class="menu_item -item09"
                @click="
                  $router.push({
                    name: 'ReviewExLearning',
                    query: { sort: 'new', keyword: '' },
                  })
                "
              >
                <em>外部教育口コミ</em>
              </a>
            </div>
            <section-new-contents
              :contents="new_contents"
              :loading="loadingNew"
              @unauthorized="unauthorized"
            ></section-new-contents>
            <section-contents
              category="サイバー関連サービス等解説コンテンツ"
              en="EXPLANATION OF CYBER-RELATED SERVICES, etc."
              routerName="Basics"
              :contents="basic"
              :loading="loadingContents"
              class="-item06"
              @unauthorized="unauthorized"
            ></section-contents>
            <section-contents
              category="みんなの支援ツール"
              en="SUPPORT TOOL"
              routerName="Tool"
              :contents="tool"
              :loading="loadingContents"
              class="-item04"
              @unauthorized="unauthorized"
            ></section-contents>
            <section-contents
              category="サイバー道場"
              en="CYBER DOJO"
              routerName="Dojo"
              :contents="dojo"
              :loading="loadingContents"
              class="-item02"
              @unauthorized="unauthorized"
            ></section-contents>
            <section-contents
              category="サイバー空間の脅威に立ち向かえ！"
              en="FACE UP"
              routerName="FaceUp"
              :contents="faceUp"
              :loading="loadingContents"
              class="-item05"
              @unauthorized="unauthorized"
            ></section-contents>
            <section-contents
              category="サイバー最新トレンド"
              en="CYBER TREND"
              routerName="Trend"
              :contents="trend"
              :loading="loadingContents"
              class="-item01"
              @unauthorized="unauthorized"
            ></section-contents>
            <section-contents
              category="CTF"
              en="CTF"
              routerName="Ctf"
              paid
              :contents="ctf"
              :loading="loadingContents"
              class="-item07"
              @unauthorized="unauthorized"
            ></section-contents>
            <section-contents
              category="上級道場"
              en="ADVANCED DOJO"
              routerName="AdvancedDojo"
              paid
              :contents="advanced_dojo"
              :loading="loadingContents"
              class="-item08"
              @unauthorized="unauthorized"
            ></section-contents>
          </div>
          <div class="side">
            <div class="status">
              <div class="status_prof">
                <figure class="status_prof_img">
                  <img :src="profileImage" width="50" height="50" alt />
                </figure>
                <div class="status_prof_cnt">
                  <p class="status_prof_aff">{{ $auth.metadata.belongs }}</p>
                  <p class="status_prof_name">
                    <em>{{ $auth.metadata.fullname }}</em
                    >様
                  </p>
                </div>
              </div>
              <p class="status_msg">
                {{ profile.goal ? profile.goal : "目標未設定" }}
              </p>
              <div class="status_mypage">
                <a class="c_btn" @click="$router.push({ name: 'Mypage' })">
                  マイページを見る
                </a>
              </div>
              <p
                class="status_info"
                v-if="profile.has_new_message"
                @click="
                  $router.push({
                    name: 'Message',
                    params: { id: profile.chat_id },
                  })
                "
              >
                JC3からのメッセージが届きました。
              </p>
              <div v-else class="status_mypage">
                <a class="c_btn -black" @click="goMessage">
                  事務局にメッセージを送る
                </a>
              </div>
              <div class="status_data">
                <dl class="status_data_dl">
                  <dt>
                    <img
                      v-if="rankImg"
                      :src="rankImg"
                      width="30"
                      height="34"
                      alt
                    />
                  </dt>
                  <dd>
                    あなたは
                    <em>{{ rank }}</em
                    >です
                  </dd>
                </dl>
                <dl class="status_data_dl">
                  <dt>
                    <img
                      src="/img/cmn/img-side-cal@2x.png"
                      width="31"
                      height="26"
                      alt
                    />
                  </dt>
                  <dd>
                    利用日数
                    <em>{{ profile.login_count }}</em
                    >日
                  </dd>
                </dl>
              </div>
            </div>
            <div class="char">
              <Character ref="character" :char="profile.character"></Character>
            </div>
            <section-topic
              title="あなたへのおすすめ"
              :contents="recommends"
              :loading="loadingRecommend"
              @unauthorized="unauthorized"
            ></section-topic>
            <section-topic
              title="直近の利用履歴"
              :contents="histories"
              :loading="loadingHistory"
              @unauthorized="unauthorized"
            ></section-topic>
          </div>
        </div>
      </div>
    </div>
    <!-- アクセス制限 -->
    <modal
      name="unauthorized"
      adaptive
      height="auto"
      width="85%"
      :maxWidth="640"
    >
      <section id="unauthorized" class="c_popup">
        <h2 class="c_popup_ttl">閲覧制限</h2>
        <p class="c_popup_lead">
          このコンテンツを閲覧する権限がありません。
          <br />閲覧制限を解除するには、所属の管理者経由で警察庁にご連絡ください。
        </p>
        <div class="c_popup_btns">
          <button
            class="c_btn"
            type="button"
            @click="$modal.hide('unauthorized')"
          >
            閉じる
          </button>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import Character from "@/components/Character";
import SectionTopic from "@/components/SectionTopic";
import SectionNewContents from "@/components/SectionNewContents";
import SectionContents from "@/components/SectionContents";
import SectionInfo from "@/components/SectionInfo";

export default {
  components: {
    Character,
    SectionTopic,
    SectionNewContents,
    SectionContents,
    SectionInfo,
  },
  data: () => ({
    loadingNew: false,
    loadingContents: false,
    profile: {
      goal: "",
      character: 0,
      login_count: 0,
      has_new_message: false,
    },
    profileImage: "",
    rank: "ブロンズ",
    rankImg: "/img/cmn/img-medal-beginner.png",

    new_contents: [],

    basic: [],
    tool: [],
    dojo: [],
    trend: [],
    faceUp: [],
    ctf: [],
    advanced_dojo: [],

    recommends: [],
    loadingRecommend: false,
    histories: [],
    loadingHistory: false,

    newMessage: false,
  }),
  created() {
    // コンテンツ
    this.fetchContents();
    // 新着
    this.fetchNewContents();
    // プロフィール
    this.fetchProfile();
    this.fetchProfileImage();
    // おすすめ
    this.fetchRecommend();
    // 履歴
    this.fetchHistory();
  },
  methods: {
    fetchContents() {
      this.loadingContents = true;

      this.$authAxios
        .get("/api/contents/top")
        .then((response) => {
          this.basic = response.data["サイバー関連サービス等解説コンテンツ"];
          this.tool = response.data["みんなの支援ツール"];
          this.dojo = response.data["サイバー道場"];
          this.trend = response.data["サイバー最新トレンド"];
          this.faceUp = response.data["サイバー空間の脅威に立ち向かえ！"];
          this.ctf = response.data["CTF"];
          this.advanced_dojo = response.data["上級道場"];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingContents = false;
        });
    },
    fetchNewContents() {
      this.loadingNew = true;

      this.$authAxios
        .get("/api/contents/new", {
          params: {
            limit: 3,
          },
        })
        .then((response) => {
          this.new_contents = response.data.result.slice(0, 3);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingNew = false;
        });
    },
    fetchProfile() {
      this.$authAxios
        .get("/api/profile")
        .then((response) => {
          this.profile = response.data;
          if (response.data.login_count >= 30) {
            this.rank = "マスター";
            this.rankImg = "/img/cmn/img-medal-master.png";
          } else if (response.data.login_count >= 14) {
            this.rank = "エキスパート";
            this.rankImg = "/img/cmn/img-medal-expert.png";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$refs.character.character();
        });
    },
    fetchProfileImage() {
      this.$authAxios
        .get("/api/profile/image", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          this.profileImage = URL.createObjectURL(blob);
        })
        .catch((error) => {
          this.profileImage = "/img/cmn/img-profile.jpg";
          console.log(error);
        })
        .finally(() => {});
    },
    fetchRecommend() {
      this.loadingRecommend = true;
      this.$authAxios
        .get("/api/contents/recommend")
        .then((response) => {
          this.recommends = this.recommends.concat(response.data.results);
          this.nextRecommend = response.data.next;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRecommend = false;
        });
    },
    fetchHistory() {
      this.loadingHistory = true;
      this.$authAxios
        .get("/api/contents/history")
        .then((response) => {
          this.histories = this.histories.concat(response.data.results);
          this.nextHistory = response.data.next;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingHistory = false;
        });
    },
    goMessage() {
      this.$router.push({
        name: "Message",
        params: { id: this.profile.chat_id },
      });
    },
    unauthorized() {
      this.$modal.show("unauthorized");
    },
  },
};
</script>

<style scoped>
@import "~@/css/home.css";
</style>
