<template>
  <section class="sec">
    <header class="sec_hd">
      <h2 class="sec_hd_ttl">
        <em>新着コンテンツ</em>
        <small>NEW CONTENT</small>
      </h2>
      <a class="sec_hd_link" @click="$router.push({ name: 'New' })"
        >すべて見る</a
      >
    </header>
    <div class="sec_contents" :class="{ c_load: loading }">
      <a
        v-for="c in contents"
        :key="c.id"
        class="sec_contents_item"
        @click="clickContents(c)"
        :style="accessableStyle(c)"
      >
        <div class="sec_contents_item_cnt">
          <time>{{ c.from_field | formatDateHyphen }}</time>
          <em>{{ c.name }}</em>
          <span>{{ c.category_name }}</span>
        </div>
        <figure class="sec_contents_item_img">
          <img
            v-if="c.image_path"
            :src="`https://${jc3LearningDomain}${c.image_path}`"
            alt
            width="65"
            height="65"
          />
          <img
            v-else
            src="http://placehold.jp/cfcfcf/4a4a4a/65x65.png?text=NO%0AIMAGE"
            alt
            width="65"
            height="65"
          />
        </figure>
      </a>
    </div>
  </section>
</template>

<script>
import { jc3LearningDomain } from "@/../config.json";
export default {
  props: {
    loading: Boolean,
    contents: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
    };
  },
  methods: {
    clickContents(contents) {
      if (this.$auth.scopes.includes("readonly:npa")) {
        if (contents.category_name === "サイバー関連サービス等解説コンテンツ") {
          this.goContents(contents);
        } else {
          this.$emit("unauthorized");
        }
      } else {
        this.goContents(contents);
      }
    },
    goContents(contents) {
      if (contents.type === 1 || contents.type === 2) {
        // CTF or 教育
        let url = `https://${this.jc3LearningDomain}/events/${contents.id}`;
        window.open(url, "_blank");
      } else if (contents.type === 4) {
        // 外部サイト
        let url = contents.external_url;
        window.open(url, "_blank");
      } else if (contents.type === 8) {
        // 動画
        if (contents.category_name === "サイバー最新トレンド") {
          this.$router.push({
            name: "TrendSingle",
            params: { id: contents.id },
          });
        } else if (
          contents.category_name === "サイバー空間の脅威に立ち向かえ！"
        ) {
          this.$router.push({
            name: "FaceUpSingle",
            params: { id: contents.id },
          });
        } else if (
          contents.category_name === "サイバー関連サービス等解説コンテンツ"
        ) {
          this.$router.push({
            name: "BasicsSingle",
            params: { id: contents.id },
          });
        }
      } else if (contents.type === 16) {
        // ツール
        this.$router.push({ name: "ToolSingle", params: { id: contents.id } });
      }
    },
    accessableStyle(contents) {
      if (!contents.accessable) {
        return {
          "pointer-events": "none",
          cursor: "not-allowed",
        };
      }
    },
  },
};
</script>
