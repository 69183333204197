<template>
  <section class="topic">
    <h3 class="topic_ttl">{{ title }}</h3>
    <ul class="topic_list" :class="{c_load: loading}">
      <li v-for="c in contents" :key="c.id">
        <a class="c_pointer" @click="clickContents(c)">
          <img v-if="c.image_path" :src="`https://${jc3LearningDomain}${c.image_path}`" alt width="65" height="65" />
          <img v-else src="http://placehold.jp/cfcfcf/4a4a4a/65x65.png?text=NO%0AIMAGE" alt width="65" height="65"/>
          <em>{{ c.name }}</em>
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
import { jc3LearningDomain } from "@/../config.json";
export default {
  props: {
    title: String,
    href: String,
    loading: Boolean,
    contents: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
    }
  },
  methods: {
    clickContents(contents) {
      if (this.$auth.scopes.includes('readonly:npa')) {
        if (contents.category_name === 'サイバー関連サービス等解説コンテンツ') {
          this.goContents(contents)
        } else {
          this.$emit('unauthorized')
        }
      } else {
        this.goContents(contents)
      }
    },
    goContents(contents) {
      if (contents.type === 1 || contents.type === 2) {
        // CTF or 教育
        let url = `https://${this.jc3LearningDomain}/events/${contents.id}`
        window.open(url, '_blank')
      } else if (contents.type === 4) {
        // 外部サイト
        let url = contents.external_url
        window.open(url, '_blank')
      } else if (contents.type === 8) {
        // 動画
        if (contents.category_name === 'サイバー最新トレンド') {
          this.$router.push({name: 'TrendSingle', params: { id: contents.id}})
        } else if (contents.category_name === 'サイバー空間の脅威に立ち向かえ！') {
          this.$router.push({name: 'FaceUpSingle', params: { id: contents.id}})
        } else if (contents.category_name === 'サイバー関連サービス等解説コンテンツ') {
          this.$router.push({name: 'BasicsSingle', params: { id: contents.id}})
        }
      } else if (contents.type === 16) {
        // ツール
        this.$router.push({name: 'ToolSingle', params: { id: contents.id}})
      }
    },
  }
}
</script>