<template>
  <div class="c_info">
      <div class="inner">
        <div class="c_info_row">
          <dl class="c_info_topic">
            <dt>JC3からのお知らせ</dt>
            <dd v-if="loading || (!loading && info.title)" :class="{c_load_info_sec: loading}">
              <a class="c_pointer" @click="$router.push({name:'InfoSingle', params: {id: info.id}})">
                <time>{{ info.start_datetime | formatDateDot }}</time>
                <em>{{ info.title }}</em>
              </a>
            </dd>
            <dd v-else-if="!loading">
              お知らせはありません。
            </dd>
          </dl>
          <a @click="$router.push({name:'Info'})" class="c_info_list">一覧を見る</a>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        id: null,
        start_datetime: '',
        title: ''
      },
      loading: false
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true

      this.$authAxios.get('/api/info')
      .then((response) => {
        if (response.data.results.length) {
          this.info = response.data.results[0]
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>